<template>
	<div class="basket-form-cost text-center h5">
		<div>
			Ваш заказ:
			<span class="ml-1">{{ itemsCost | nf }} ₽</span>
		</div>
		<div v-if="form.POSTCARD_ID">
			Открытка:
			<span class="ml-1">{{ postcardsCost | nf }} ₽</span>
		</div>
		<template v-if="deliveryCost">
			<div>
				Доставка:
				<span class="ml-1">{{ deliveryCost | nf }} ₽</span>
			</div>
		</template>
		<template v-if="deliveryCost===null">
			<p class="basket-form-cost__note limited mt-8 mx-auto">Стоимость доставки и итоговая стоимость заказа<br/>будет
				рассчитана для Вас менеджером<br/>после оформления заказа</p>
		</template>
		<h3 v-else class="mt-4">ИТОГО: <span class="ml-1">{{ total | nf }} ₽</span></h3>
	</div>
</template>

<script>
import {destinations, deliveryTypes, deliveryCost} from '@/store/delivery';

export default {
	name: "basket-form-cost",
	props: {
		form: {},
	},
	data() {
		return {}
	},
	computed: {
		items() {
			return this.$store.state.session?.basket;
		},
		destination() {
			return destinations.find(el => el.id === this.form.destinationId);
		},
		deliveryType() {
			return deliveryTypes.find(el => el.id === this.form.deliveryTypeId);
		},
		deliveryZone() {
			return this.destination?.zones?.find(el => el.id === this.form.deliveryZoneId);
		},
		/**
		 * null - не определено!
		 * 0 - бесплатно!
		 * @returns {*}
		 */
		deliveryCost() {
			return deliveryCost(this.form, this.costBeforeDelivery);
		},
		postcardsCost() {
			return Number(this.form.POSTCARD_PRICE) || 0;
		},
		itemsCost() {
			return this.items.reduce((sum, item) => sum + item.PRICE * Number(item.AMOUNT), 0);
		},
		costBeforeDelivery() {
			const productsTotal = this.itemsCost;
			const postcardCost = this.postcardsCost;
			return productsTotal + postcardCost;
		},
		total() {
			const productsTotal = this.itemsCost;
			const deliveryCost = this.deliveryCost;
			if (deliveryCost === null) return null;
			const postcardCost = this.postcardsCost;
			return productsTotal + deliveryCost + postcardCost;
		},
	}
}
</script>

<style lang="scss">

.basket-form-cost {
	&__note {
		border: 2px solid $golden;
		//background: $golden-light;
		padding: 20px;
	}
}
</style>